import React, { useState, useEffect } from "react";
import "../Items/team.css";
import TeamWork from "./TeamWork";
import "animate.css";
import img1 from "../assets/img1.jpg";
import HeaderWithBackground from "./HeaderWithBackground";
import BgImg from "./BgImg";

const Teams = () => {
  return (
    <section className="container mx-auto text-white">
      <HeaderWithBackground backgroundImage={img1} title="MEMBER" />
      <BgImg>
        <TeamWork />
      </BgImg>
    </section>
  );
};

export default Teams;
