import React, { useRef, useState, useLayoutEffect } from "react";
import "../Items/card.css"; // カスタムスタイル（date-headerなど）を含む
import { motion } from "framer-motion";
import { PROJECTS } from "../constants";
import { HashLink } from "react-router-hash-link";

const CardTest = () => {
  const [width, setWidth] = useState(0);
  const sliderWrapper = useRef();

  useLayoutEffect(() => {
    if (sliderWrapper.current) {
      setWidth(
        sliderWrapper.current.scrollWidth - sliderWrapper.current.offsetWidth
      );
    }
  }, []);

  return (
    <div className="container mx-auto text-white my-24 ">
      <h2 className="mb-12 text-center text-5xl md:text-7xl font-bold text-orange-500 title-text">
        NEWS
      </h2>
      <motion.div
        className="slider_wrapper"
        ref={sliderWrapper}
        whileTap={{ cursor: "grabbing" }}
      >
        <motion.div
          className="inner_crousel flex cursor-grab md:justify-start"
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          whileTap={{ cursor: "grabbing" }}
        >
          {PROJECTS.map((card, index) => (
            <div
              key={index}
              className="card border-2 border-orange-500 w-[350px] md:w-[280px] sm:w-[240px] md:ml-0 sm:ml-0"
            >
              <div className="card_img h-[300px] md:h-[240px] sm:h-[200px]">
                <img
                  src={card.image}
                  alt="avatar"
                  draggable={false}
                  className="w-full h-full object-cover"
                />
              </div>
              <h2 className="date-header text-base md:text-sm sm:text-xs">
                {card.date}
              </h2>
              <div className="text-xl md:text-lg sm:text-base font-semibold py-4">
                <HashLink
                  to={card.link}
                  className="title text-white hover:text-orange-500 transition-colors duration-300"
                >
                  {card.title}
                </HashLink>
              </div>
            </div>
          ))}
        </motion.div>
      </motion.div>
      <div className="flex justify-center cursor-pointer ">
        <button className="group my-16 px-12 py-4 relative m-1 cursor-pointer overflow-hidden rounded-md border-2 border-orange-500  font-mono font-semibold">
          <span className="ease absolute top-1/2 h-0 w-64 origin-center -translate-x-20 rotate-45 bg-orange-500 transition-all duration-300 group-hover:h-64 group-hover:-translate-y-32"></span>

          <HashLink to="/news">
            <span className="ease relative text-orange-500 text-xl transition duration-300 group-hover:text-white">
              more News
            </span>
          </HashLink>
        </button>
      </div>
    </div>
  );
};

export default CardTest;
