import React from "react";
import { motion } from "framer-motion";

const Section = ({ title, subtitle, content }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      className="text-white my-5 sm:my-10 md:my-20 font-titleFont flex flex-col justify-center items-center gap-4 sm:gap-6 md:gap-12 max-w-5xl mx-auto px-3 sm:px-4 md:px-6 relative z-10" // Adjusted my-10 to my-5
    >
      <motion.h2
        variants={itemVariants}
        className="text-2xl sm:text-4xl md:text-8xl font-bold text-white uppercase tracking-wider font-minchoFont"
      >
        {title}
      </motion.h2>
      <motion.h1
        variants={itemVariants}
        className="text-3xl sm:text-5xl md:text-4 xl font-minchoFont leading-tight"
      >
        {subtitle}
      </motion.h1>
      <motion.div
        variants={itemVariants}
        className="text-base sm:text-lg md:text-2xl leading-relaxed backdrop-blur-sm"
      >
        {content}
      </motion.div>
    </motion.div>
  );
};

const AboutMain1 = () => {
  return (
    <div className="relative w-full ">
      <div className="absolute top-0 left-0 right-0 h-10 sm:h-20 md:h-40 bg-gradient-to-b to-transparent z-10"></div>

      <div className="w-full min-h-screen flex flex-col font-titleFont py-5 sm:py-10 md:py-20 overflow-hidden relative">
        <div className="mb-14 sm:mb-10 md:mb-20">
          {" "}
          {/* Adjusted mb-10 to mb-5 */}
          <Section
            title="Mission-使命"
            subtitle="ゲームをもっと身近に"
            content={
              <ul className="list-inside space-y-2 list-none md:text-2xl text-lg">
                <li>
                  Our’sShipGamingはゲーム、e-sportsという文化をスポーツと同じように広く、一般層にまで認知され馴染みがあり応援される文化にすべく活動していきます。
                </li>
                <li>
                  そしてe-sports選手という職業を憧れ、
                  誇りのある職業へ昇華させるため活動します。
                </li>
              </ul>
            }
          />
        </div>
        <div className="mb-5 sm:mb-10 md:mb-20">
          {" "}
          {/* Adjusted mb-10 to mb-5 */}
          <Section
            title="Vision-展望"
            subtitle={
              <div className="flex flex-col">
                <span className="text-2xl md:text-4xl">
                  ゲームを通してのコミュニケーションを豊かに
                </span>
                <br />
                <span className=" text-center md:text-5xl text-2xl">
                  e-sports界のシンボルになる
                </span>
              </div>
            }
            content={
              <ul className="list-inside space-y-2 list-none">
                <li>
                  ゲームは今やただの趣味ではなく、部活などと同様に周囲とのコミュニケーションや
                  成功体験、挫折などを経験し人間として成長するツールのひとつです。
                </li>
                <li>
                  これまで以上にゲームという文化を成長させコミュニケーションツールの
                  一つとして社会に浸透させます。
                </li>
                <li>
                  Our’sShipGamingは日本のe-sports業界をリードするシンボルのような存在を目指します。
                </li>
              </ul>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AboutMain1;
