import image1 from "../assets/logo.png";
import nyanmerry from"../assets/nyanmerry.png"
import matsutash from"../assets/matsutash.jpg"
import aries from"../assets/aries.png"
import harup from "../assets/harupi.png";
import manta from "../assets/manta.jpg";
import apex from "../assets/apex3man.png";
import yoshiaki from "../assets/yoshiaki.png";
import keigo from "../assets/keigo.jpg";
import damio from "../assets/damio.jpeg";
import coming from "../assets/comings.png";
import newscard from "../assets/newscarod.jpg";
import sf6icon from "../assets/sf6icon.png";
import apexBG1 from "../assets/apexBG1.png";
import apexicon1 from "../assets/apexicon1.png";
import owBG from "../assets/owBG.png";
import owIcon from "../assets/owIcon.png";
import sf6BG from "../assets/sf6BG.png";
import osgBG from "../assets/osgBG.png";
import osgicon from "../assets/osgicon.png";

import kazuya from "../assets/kazuya.jpg";

import { FiYoutube } from "react-icons/fi";
import {
  FaXTwitter,
 
  FaInstagram,
} from "react-icons/fa6";
export const NAVIGATION_LINKS = [
    { label: "ABOUT", href: "#about" },
    { label: "NEWS", href: "#projects" },
    { label: "TEAM", href: "#skills" },
    { label: "HISTORY", href: "#award" },
    { label: "CONTACT", href: "#testimonials" },
    { label: "SES", href: "#ses" },
  ];
  export const PROJECTS = [
    
    {
      title: "StreetFighter部門設立",
      date:
        "09.28.2024",
      image: sf6icon,
      link:"/news"
    },
    {
      title: "APEX部門設立",
      date:
        "09.20.2024",
      image: apex,
      link:"/news"
    },
   
    {
      title: "社員イベント開催のお知らせ",
      date:
        "09.20.2024",
      image: newscard,
      link:"/news"
    },
    {
      title: "OursShipGaming設立",
      date:
        "08.20.2024",
      image: newscard,
      link:"/news"
    },
   
    
  ];

  export const SOCIAL_MEDIA_LINKS = [
   
    {
      href: "https://www.youtube.com/@OursShipGaming",
      icon: <FiYoutube fontSize={25} className="hover:opacity-80" />,
    },
    {
      href: "https://x.com/OursShip",
      icon: <FaXTwitter fontSize={25} className="hover:opacity-80" />,
    },
   
    
  ];

  export const Data = [
    {
        id: 1,
        image: matsutash,
        title: "MatsuTash",
        category: "APEX",
        description: "常に冷静で後方からチームを支える。",
        description1: "的確な指示と、一人で残っても順位を上げて貢献する。",
        description2: "チーム最年長で、IGLとして再度世界へ！",
        youtubeId: "VbL5CoWaml0",
        record:"GROW Cup 1st",
        record1:"ALGS: 2023 Split 1 Playoffs 8th",
        record2:"ALGS: 2022 Split 1 Pro League - APAC North 1st",
        record3:"SoulZ Season #4 2nd",
        record4:"ALGS: 2022 Championship 4th",
        record5:"ALGS: 2022 Split 2 Playoffs 9th",
        record6:"ALGS: 2022 Split 2 Pro League - APAC North 3rd",
        record7:"ALGS: 2021 Split 1 Pro League - APAC North 10th",
        record8:"ALGS Championship 2021 - APAC North 8th",
        record9:"Takaoka Esports Cup - Season 1 1st",
       
        youtube:"https://www.youtube.com/@matsutash",
        twitter:"https://twitter.com/MatsuTasu",
        twitch:"https://www.twitch.tv/matsutash",
        subtitle:"ApexLegends",
    },
    {
        id: 2,
        image: nyanmerry,
        title: "Nyanmerry",
        category: "APEX",
        description: "Aimに絶対的な自信を持つ。",
        description1: "本人曰く、Aimだけでここまで来てしまった。",
        description2: "配信では甘めボイスでファンを増やしている。",
        youtubeId: "FVZHRmRhZ-k",
        record:"S21プレデター世界24位 日本6位",
        record1:"Year4 split2 pro League出場",
        record2:"TAIMAN+ オフライン大会出場",
        youtube:"https://www.youtube.com/@nyanmerrypop",
        twitter:"https://twitter.com/Nyanmerrypop",
        twitch:"https://www.twitch.tv/nyanmerrypop",
        subtitle:"ApexLegend",
    },
    {
        id: 3,
        image: aries,
        title: "ShizuAries",
        category: "APEX",
        description: "犬好きな犬系男子。",
        description1: "配信からも犬の声が聞こえてくる。",
        description2: "まだまだ若くてゲームセンスも抜群なのでこれからの活躍に期待が集まる。",
        youtubeId: "ttWhT-_MN-A" ,
        youtube:"https://www.youtube.com/@ShizuAriy",
        twitter:"https://twitter.com/ShizuAries",
        twitch:"https://www.twitch.tv/shizuaries",
        subtitle:"ApexLegend",
        record:"s15最高日本4位",
    },
    {
        id: 4,
        image: kazuya,
        title: "かずやしゃん",
        category: "SF6",
        description: "”力”のかずやしゃん",
        description1: "過去にはFPSやLOLをやっていたことも。",
        description2: "大胆に相手の隙を突き、一瞬で試合を終わらせるプレイが得意",
        record:"Fighters Crossover【全国大会#00】",
        record1:"川崎代表として本戦出場（ベスト32",
        twitter:"https://x.com/k4zuya_LOL",
        twitch:"https://www.twitch.tv/kazuyasyan",
       
        subtitle:"Street Fighter VI",
    },
    {
        id: 5,
        image: coming,
        title: "COMING SOON",
        category: "SF6",
        description: "反射神経では人間界最強の野生児。顔はゴリラに似ている。",
       youtube:"https://www.youtube.com/@OursShipGaming",
        subtitle:"Street Fighter VI",
    },
    {
        id: 6,
        image: manta,
        title: "塩田悠麻(manta)",
        category: "STAFF",
        description: "Our’ｓShipGamingを運営するアワーズシップ株式会社の代表。選手がゲームに集中できるような環境を作るべく尽力中",
        twitter:"https://x.com/shiota_OS",
        youtube:"https://www.youtube.com/@OursShipGaming",
       
        subtitle:"CEO",
    },
    {
        id: 7,
        image: keigo,
        title: "SlothEP",
        category: "STAFF",
        description: "選手が輝く瞬間をより良く見せられるような動画をつくり、皆さんにお届けしたいと思います。",
        description1: "ぜひ楽しんでください",
        subtitle:"director",
        youtube:"https://www.youtube.com/@OursShipGaming",
    },
    {
        id: 8,
        image: harup,
        title: "HaruP",
        category: "STAFF",
        description: "選手・運営経験を活かし選手が最も輝ける環境をサポートします。",
        description1:"チーム一つになり更なる高みへ...",
        subtitle:"STAFF",
        record:"スターダストプロモーション所属",
        record1:"日本テレビ『マツコ会議』出演",
        record2:"AbemaTimes 2週連続特集",
        record3:"東京アニメ・声優専門学校 学園祭 ゲスト出演",
        record4:"フジテレビ『いいすぽ』出演",
        record9:"女子プロチーム運営(PUBGmobile・apex・VALORANT)",
        twitter:"https://x.com/hhpp_game/media",
        youtube:"https://www.youtube.com/@OursShipGaming",
    },
    {
        id: 9,
        image: yoshiaki,
        title: "MARUKOKO",
        category: "STAFF",
        description: "ノリと勢いで楽しんでるゲーマー",
        description1: "子育てとゲームと仕事の三刀流。リアルも3流",
        description2: "選手により良い環境を作るためのサポートしてます。",
        twitter:"https://x.com/Inhumanity_",
        subtitle:"STAFF",
        youtube:"https://www.youtube.com/@OursShipGaming",
    },
    {
        id: 10,
        image: damio,
        title: "たまお",
        category: "STAFF",
        description: " Overwatchとvalorantの2タイトルでプロゲーマーとして活動をしていた経歴を持ちほとんどのFPSタイトルで最高ランクに到達している。",
        description1: " 今までの経験を活かし、選手により良い環境を作るために全力でサポートしていきます。",
        youtubeId:"TgfMR1p35yU",
        subtitle:"STAFF",
    },
    
]


  export const projectNav =[
    {
      name:"ALL",
    },
    {
      name:"APEX",
    },
    {
      name:"SF6",
    },
    {
      name:"STAFF",
    }
  ];
  
  export const history = [
    {
      date: "2024.9",
      experience: "Our’ｓShipGaming設立",
    },
    {
      date: "2024.9",
      experience: "APEX LEGENDS部門設立",
    },
    {
      date: "2024.9",
      experience: "StreetFighter部門設立",
    },
   
  ];

  export const results =[

    {
      date: "2024.9",
      experience: "Apex Legends Global Series: 2024 Last Chance Qualifier 出場",
    },
    
  ]
  export const exploreWorlds = [
    {
      id: 'world-1',
      imgUrl: owBG,
      title: 'Overwatch',
      imgIcon: owIcon,
    },
    {
      id: 'world-2',
      imgUrl: apexBG1,
      title: 'APEXLEGENDS',
      imgIcon: apexicon1,
    },
    {
      id: 'world-3',
      imgUrl: sf6BG,
      title: 'STREET FIGHTER VI',
      imgIcon: sf6icon,
    },
    {
      id: 'world-4',
      imgUrl: osgBG,
      title: "STREMER",
      imgIcon: osgicon,
    },
   
  ];

  