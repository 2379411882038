import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FiYoutube } from "react-icons/fi";

const SocialIcons = () => {
  return (
    <div className="fixed right-4 bottom-12 flex flex-col space-y-4 z-50 sm:right-8 md:right-12 lg:right-8">
      <a
        href="https://twitter.com/OursShipGaming"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-400 hover:text-white bg-gray-800 hover:bg-blue-600 p-2 rounded-full transition-all duration-300 sm:p-3 md:p-4"
      >
        <FaXTwitter size={24} className="sm:text-lg md:text-xl" />
      </a>

      <a
        href="https://www.youtube.com/@OursShipGaming"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-400 hover:text-white bg-gray-800 hover:bg-pink-600 p-2 rounded-full transition-all duration-300 sm:p-3 md:p-4"
      >
        <FiYoutube size={24} className="sm:text-lg md:text-xl" />
      </a>
    </div>
  );
};

export default SocialIcons;
