import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import { FaTimes, FaBars } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const scrollToTop = () => {
    const yCoordinate = 0;
    window.scrollTo({
      top: yCoordinate,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <nav className="fixed top-0 left-0 right-0 z-50 lg:top-6">
        {/* Desktop Menu */}
        <div
          className="mx-auto hidden max-w-3xl items-center justify-center rounded-lg
                     bg-black/20 py-4 pr-2 backdrop-blur-lg lg:flex"
        >
          <div className="flex justify-between gap-8 ">
            <div className="px-12">
              <HashLink smooth to="/" onClick={scrollToTop}>
                <img src={logo} width={160} alt="logo" />
              </HashLink>
            </div>
          </div>
          <div>
            <ul className="flex items-center gap-6">
              <li className="text-lg text-white hover:text-orange-500">
                <HashLink smooth to="/about">
                  ABOUT
                </HashLink>
              </li>
              <li className="text-lg text-white hover:text-orange-500">
                <HashLink smooth to="/news">
                  NEWS
                </HashLink>
              </li>
              <li className="text-lg text-white hover:text-orange-500">
                <HashLink smooth to="/member">
                  MEMBER
                </HashLink>
              </li>
              <li className="text-lg text-white hover:text-orange-500">
                <HashLink smooth to="/history">
                  HISTORY
                </HashLink>
              </li>
              <li className="text-lg text-white hover:text-orange-500">
                <a
                  href="https://oursship.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SES
                </a>
              </li>
              <li className="text-lg text-white hover:text-orange-500">
                <HashLink smooth to="/form">
                  CONTACT
                </HashLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className="rouded-lg backdrop-blur-md lg:hidden">
          <div className="flex items-center justify-between p-4">
            <HashLink smooth to="/" onClick={scrollToTop}>
              <img src={logo} width={130} alt="logo" className="" />
            </HashLink>
            <div className="flex items-center">
              <button
                className="focus:outline-none lg:hidden text-white"
                onClick={toggleMobileMenu}
              >
                {isMobileMenuOpen ? (
                  <FaTimes className="w-6 h-6" />
                ) : (
                  <FaBars className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>
        {isMobileMenuOpen && (
          <ul className="flex flex-col gap-4 backdrop-blur-md text-white p-4">
            <li className="block w-full text-xl font-semibold">
              <HashLink smooth to="/about">
                ABOUT
              </HashLink>
            </li>
            <li className="block w-full text-xl font-semibold">
              <HashLink smooth to="/news">
                NEWS
              </HashLink>
            </li>
            <li className="block w-full text-xl font-semibold">
              <HashLink smooth to="/member">
                MEMBER
              </HashLink>
            </li>
            <li className="block w-full text-xl font-semibold">
              <HashLink smooth to="/history">
                HISTORY
              </HashLink>
            </li>
            <li className="block w-full text-xl font-semibold">
              <a
                href="https://oursship.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                SES
              </a>
            </li>
            <li className="block w-full text-xl font-semibold">
              <HashLink smooth to="/form">
                CONTACT
              </HashLink>
            </li>
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
