import { useState } from "react";
import { motion } from "framer-motion";
import styles from "../Items/Explore.css";
import { exploreWorlds } from "../constants";

import ExploreCard from "./ExploreCard";
import { HashLink } from "react-router-hash-link";

const Explore = () => {
  const [active, setActive] = useState("world-2");

  const staggerContainer = (staggerChildren, delayChildren) => ({
    hidden: {},
    show: {
      transition: {
        staggerChildren,
        delayChildren,
      },
    },
  });

  return (
    <section className="container mx-auto my-24" id="explore">
      <h2 className="mb-6 text-center text-5xl md:text-7xl font-bold text-orange-500 title-text">
        TEAM
      </h2>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <div className="mt-[50px] flex lg:flex-row flex-col min-h-[70vh] gap-5 ">
          {exploreWorlds.map((world, index) => (
            <ExploreCard
              key={world.id}
              {...world}
              index={index}
              active={active}
              handleClick={setActive}
            />
          ))}
        </div>
      </motion.div>
      <div className="flex justify-center cursor-pointer ">
        <button className="group my-16 px-12 py-4 relative m-1 cursor-pointer overflow-hidden rounded-md border-2 border-orange-500  font-mono font-semibold">
          <span className="ease absolute top-1/2 h-0 w-64 origin-center -translate-x-20 rotate-45 bg-orange-500 transition-all duration-300 group-hover:h-64 group-hover:-translate-y-32"></span>
          <HashLink to="/member">
            <span className="ease relative text-orange-500 transition duration-300 group-hover:text-white">
              MEMBER
            </span>
          </HashLink>
        </button>
      </div>
    </section>
  );
};

export default Explore;
