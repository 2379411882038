import { motion } from "framer-motion";

import styles from "../Items/Explore.css";

const ExploreCard = ({
  id,
  imgUrl,
  title,
  index,
  active,
  handleClick,
  imgIcon,
}) => (
  <motion.div
    className={`relative ${
      active === id ? "lg:flex-[3.5] flex-[10]" : "lg:flex-[0.5] flex-[2]"
    } flex items-center justify-center min-w-[170px] h-[700px] transition-[flex] duration-[0.7s] ease-out-flex cursor-pointer border-2 border-orange-500  rounded-xl`}
    onClick={() => handleClick(id)}
  >
    <img
      src={imgUrl}
      alt="planet-04"
      className="absolute w-full h-full object-cover rounded-xl"
    />
    {active !== id ? (
      <h3 className="font-semibold sm:text-[26px] text-[18px] text-white absolute z-0 lg:bottom-20 lg:rotate-[-90deg] lg:origin-[0,0]">
        {title}
      </h3>
    ) : (
      <div className="absolute bottom-0 p-4 flex w-full  bg-[rgba(0,0,0,0.5)] rounded-b-[24px] h-[115px] items-center gap-4">
        <h2 className="mt-[12px] font-semibold sm:text-[32px] text-[24px] text-orange-500">
          {title}
        </h2>
        <div
          className={`${styles.flexCenter} w-[40px] h-[40px]  glassmorphismE `}
        >
          <img src={imgIcon} alt="headset" />
        </div>
      </div>
    )}
  </motion.div>
);

export default ExploreCard;
